<template>
  <div class="ta-wrapper">
    <div class="ta-data">
      <textarea
        @input="updateValue"
        :style="{ minHeight: height }"
        required
        :value="modelValue"
        v-bind="$attrs"
      ></textarea>
      <label v-if="label" :class="[animateType]">
        <span v-if="starLabel">*</span>{{ label }}</label
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'BoxTextarea',
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number]
    },
    starLabel: {
      type: Boolean,
      default: false
    },
    animateType: {
      type: String,
      default: 'remove'
    },
    height: {
      type: String,
      default: '200px'
    }
  },
  methods: {
    updateValue(e) {
      this.$emit('update:modelValue', e.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.ta-wrapper {
  width: 100%;
  margin: 0;
  label {
    position: absolute;
    font-size: 15px;
    top: 5px;
    right: 10px;
    color: #b4b4b4;
    pointer-events: none;
    transition: all 0.5s;

    &::before {
      position: relative;
      content: '';
      color: #c69d4e;
      font-size: 20px;
      top: 6px;
      margin-left: 3px;
    }

    span {
      color: #c69d4e;
      position: relative;
      top: 7px;
      margin-left: 4px;
      font-size: 20px;
    }
  }
  .ta-data {
    width: 100%;
    position: relative;

    textarea {
      text-align: right;
      outline: none;
      width: 100%;
      font-size: 15px;
      resize: none;
      border: 1px solid #bbb;
      color: #000;
      border-radius: 15px;
      padding: 10px;
      box-sizing: border-box;
      // min-height: 140px;
      &::placeholder {
        position: relative;
        font-size: 15px;
        color: #b4b4b4;
        bottom: 2px;
      }

      &:focus,
      &:valid {
        ~ label.decrease {
          transform: translateY(-25px);
          font-size: 15px;
          // color: #c69d4e;
        }
        ~ label.remove {
          opacity: 0;
        }
      }
    }
  }
}
</style>
